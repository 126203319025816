import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


export default function Header() {
  const [headerShow, setHeaderShow] = useState("home-purple-gradient");

  const toggleHeaderShow = () => {
    if (headerShow === "home-purple-gradient") {
      setHeaderShow("mobile-menu-visible");
    } else {
      setHeaderShow("home-purple-gradient");
    }
  };

  useEffect(() => {
    document.body.className = headerShow;
  }, [headerShow]);
  return (
    <div>
      <header id="header" className="header-layout1">
        <div id="sticky-header" className="menu-area transparent-header">
          <div className="container custom-container">
            {/*  */}
            <div className="row">
              <div className="col-12">
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <Link to="/">
                        <img
                          src="assets/img/logo/logo.svg"
                          style={{ height: "55px" }}
                          alt="Logo"
                          className="d-md-block d-sm-none d-none"
                        />
                      </Link>
                      <Link to="/">
                        <img
                          src="favicon.png"
                          style={{ height: "55px" }}
                          alt="Logo"
                          className="d-md-none d-sm-block d-block"
                        />
                      </Link>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li className="active menu-item-has-children">
                          <a className="section-link" href="#">
                            {" "}
                          </a>
                          {/* <ul className="sub-menu">
                      <li><a href="index.html">ICO Investment</a></li>
                      <li className="active"><a href="index-2.html">Blockchain</a></li>
                    </ul> */}
                        </li>
                        <li>
                          <NavLink to="/" className="section-link">
                            Home
                          </NavLink>
                        </li>
                        <li>

                          <HashLink smooth to='/#about' className="section-link">
                            About Us
                          </HashLink>
                        </li>
                        <li className="menu-item-has-children">
                          <NavLink to='/governance'>Governance</NavLink>
                        </li>
                        <li>
                          <NavLink to='/transparency'> Transparency</NavLink>
                        </li>
                        <li>
                          <a
                            href="https://onmax.gitbook.io/docs-onmax"
                            target="_blank"
                            className="section-link"
                          >
                            Docs{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="header-action">
                      <ul className="list-wrap">
                        <li className="header-login">
                          <a
                            className="new-btn-l"
                            href="http://app.onmaxyield.farm/"
                            target="_blank"
                          >
                            Launch App
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="mobile-nav-toggler"
                      onClick={toggleHeaderShow}
                    >
                      <i className="fas fa-bars" />
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Menu  */}
        <div className="mobile-menu">
          <nav className="menu-box">
            <div className="close-btn" onClick={toggleHeaderShow}>
              <i className="fas fa-times" />
            </div>
            {/* <div className="na">
              <a href="/">
                <img
                  src="assets/img/logo/logo.svg"
                  style={{ height: "35px" }}
                  alt="Logo"
                />
              </a>
            </div> */}
            <div className="menu-outer">
              {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
              {/* mobile-menu-visible */}
            </div>
            <ul className="navigation">
              <li className="active menu-item-has-children">
                <a className="section-link" href="#">
                  {" "}
                </a>
                {/* <ul className="sub-menu">
                      <li><a href="index.html">ICO Investment</a></li>
                      <li className="active"><a href="index-2.html">Blockchain</a></li>
                    </ul> */}
              </li>
              <li>
                <NavLink to='/' className="section-link">
                  Home
                </NavLink>
              </li>
              <li>
              <HashLink smooth to='/#about' className="section-link">
                            About Us
                          </HashLink>
              </li>
              <li className="menu-item-has-children">
                <NavLink to='/governance'>Governance</NavLink>
              </li>
              <li>
                <NavLink to='/transparency'> Transparency</NavLink>
              </li>
            </ul>
            <ul className="list-wrap mt-3 ">
              <li>
                <a
                  href="https://onmax.gitbook.io/docs-onmax"
                  target="_blank"
                  className="section-link text-white "
                >
                  Docs{" "}
                </a>
              </li>
              <br />
              <li className="header-login">
                <a
                  className="btn2 new-btn-l mx-3"
                  href="http://app.onmaxyield.farm/"
                  target="_blank"
                >
                  Launch App
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="menu-backdrop" />
        {/* End Mobile Menu */}
      </header>
    </div>
  );
}

import React from "react";
import Header from "../Coman/Header";
import Footer from "../Coman/Footer";
import TransparencyHero from "./TransparencyHero";

export default function Transparency() {
  return (
    <div>
      <div className="home-purple-gradient">
        <Header />
        <TransparencyHero />
        <Footer />
      </div>
    </div>
  );
}

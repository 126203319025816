import React from "react";

export default function TransparencyHero() {
  return (
    <div>
      <div>
        <div
          className="wcu-area-1 pt-140 pb-140 position-relative"
          id="feature"
        >
          <div className="bg-gradient-1">
            <img src="assets/img/update/bg/bg-gradient1-1.jpg" alt="img" />
          </div>
          <div className="container">
            <div className="mb-25">
              <h1 class=" text-new">Onmax Transparency</h1>
            </div>
            <div className="text-start mt-50">
              <p class="sec-text text-white">
                We believe that being open about how our Onmax Protocol witch is
                DeFi (Decentralized Finance) project operates is crucial for
                gaining trust. To stick to this promise, we share all the
                important contract details here. This gives everyone involved a
                clear understanding of how the project functions. By openly
                sharing information about our smart contracts, we make sure
                everyone is accountable and can make well-informed choices. This
                strengthens the values of decentralization and trust in the DeFi
                community.
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="mt-100">
                <div className="row justify-content-center">
                  <div className="col-lg-6 text-start">
                  <div className="px-4 text-break text-start">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Onmax Token:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Stretegicsale Onmax:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      DexLiquidity Onmax:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Onmax Defi:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0x2e32b568e82e80ec54ed998cadd92d4d88acc378
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Onmax Custody:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0xff40693bb14bc5dcb84d608101e8963b13e2aaf8
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Europe Affiliate:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0x384cc4ced095b2fbb30ce136a857512297c226f2
                      </a>
                    </p>
                  </div>
                  </div>
                  <div className="col-lg-6 text-start">
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Privatesale Onmax:
                    </h3>
                    <p className="paragraf" >
                      <a href="#" className="hover-id">
                        0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Ecosystem Onmax:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Asia Affilate:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Onmax Community Reward (CCR):
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0x0922e230c8af797132b69031cac79105c78169fc
                      </a>
                    </p>
                  </div>
                  <div className="px-4 text-break">
                    <h3 style={{ fontSize: "20px" }} className="text-new">
                      Onmax Protocol:
                    </h3>
                    <p className="paragraf">
                      <a href="#" className="hover-id">
                        0x384cc4ced095b2fbb30ce136a857512297c226f2
                      </a>
                    </p>
                  </div>
                  </div>
                </div>
                
              
             
              
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
